<template>
    <b-overlay :show="formShow" rounded="sm" no-fade>
        <validation-observer ref="userRules">
            <b-card>
                <b-row class="mb-2">
                    <table class="table table-sm">
                        <thead>
                            <tr>
                                <th scope="col">Homepage Settings</th>
                            </tr>
                        </thead>
                    </table>
                </b-row>

                <!-- image upload -->

                <b-row class="mt-2">
                    <b-col cols="12" sm="12" md="3" lg="3" xl="3">
                        <b-form-group label="Select Text Colour" label-for="gradeLeves">
                            <validation-provider #default="{ errors }" name="chartColour">
                                <verte display="widget" :showHistory="null" v-model="setting.mondayText" model="hex"></verte>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col cols="12" sm="12" md="3" lg="3" xl="3">
                        <b-form-group label="Select Background Colour" label-for="gradeLeves">
                            <validation-provider #default="{ errors }" name="chartColour">
                                <verte display="widget" :showHistory="null" v-model="setting.mondayColour" model="hex"></verte>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col cols="12" sm="12" md="3" lg="3" xl="3">
                        <b-form-group label="Preview" label-for="gradeLeves">
                            <template>
                                <b-badge :style="{ 'background-color': setting.mondayColour, color: setting.mondayText }" class="text-capitalize">
                                    MONDAY
                                </b-badge>
                            </template>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row class="mt-2">
                    <b-col cols="12" sm="12" md="3" lg="3" xl="3">
                        <b-form-group label="Select Text Colour" label-for="gradeLeves">
                            <validation-provider #default="{ errors }" name="chartColour">
                                <verte display="widget" :showHistory="null" v-model="setting.tuesdayText" model="hex"></verte>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col cols="12" sm="12" md="3" lg="3" xl="3">
                        <b-form-group label="Select Background Colour" label-for="gradeLeves">
                            <validation-provider #default="{ errors }" name="chartColour">
                                <verte display="widget" :showHistory="null" v-model="setting.tuesdayColour" model="hex"></verte>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col cols="12" sm="12" md="3" lg="3" xl="3">
                        <b-form-group label="Preview" label-for="gradeLeves">
                            <template>
                                <b-badge :style="{ 'background-color': setting.tuesdayColour, color: setting.tuesdayText }" class="text-capitalize">
                                    TUESDAY
                                </b-badge>
                            </template>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row class="mt-2">
                    <b-col cols="12" sm="12" md="3" lg="3" xl="3">
                        <b-form-group label="Select Text Colour" label-for="gradeLeves">
                            <validation-provider #default="{ errors }" name="chartColour">
                                <verte display="widget" :showHistory="null" v-model="setting.wednesdayText" model="hex"></verte>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col cols="12" sm="12" md="3" lg="3" xl="3">
                        <b-form-group label="Select Background Colour" label-for="gradeLeves">
                            <validation-provider #default="{ errors }" name="chartColour">
                                <verte display="widget" :showHistory="null" v-model="setting.wednesdayColour" model="hex"></verte>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col cols="12" sm="12" md="3" lg="3" xl="3">
                        <b-form-group label="Preview" label-for="gradeLeves">
                            <template>
                                <b-badge :style="{ 'background-color': setting.wednesdayColour, color: setting.wednesdayText }" class="text-capitalize">
                                    WEDNESDAY
                                </b-badge>
                            </template>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row class="mt-2">
                    <b-col cols="12" sm="12" md="3" lg="3" xl="3">
                        <b-form-group label="Select Text Colour" label-for="gradeLeves">
                            <validation-provider #default="{ errors }" name="chartColour">
                                <verte display="widget" :showHistory="null" v-model="setting.thursdayText" model="hex"></verte>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col cols="12" sm="12" md="3" lg="3" xl="3">
                        <b-form-group label="Select Background Colour" label-for="gradeLeves">
                            <validation-provider #default="{ errors }" name="chartColour">
                                <verte display="widget" :showHistory="null" v-model="setting.thursdayColour" model="hex"></verte>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col cols="12" sm="12" md="3" lg="3" xl="3">
                        <b-form-group label="Preview" label-for="gradeLeves">
                            <template>
                                <b-badge :style="{ 'background-color': setting.thursdayColour, color: setting.thursdayText }" class="text-capitalize">
                                    THURSDAY
                                </b-badge>
                            </template>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row class="mt-2">
                    <b-col cols="12" sm="12" md="3" lg="3" xl="3">
                        <b-form-group label="Select Text Colour" label-for="gradeLeves">
                            <validation-provider #default="{ errors }" name="chartColour">
                                <verte display="widget" :showHistory="null" v-model="setting.fridayText" model="hex"></verte>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col cols="12" sm="12" md="3" lg="3" xl="3">
                        <b-form-group label="Select Background Colour" label-for="gradeLeves">
                            <validation-provider #default="{ errors }" name="chartColour">
                                <verte display="widget" :showHistory="null" v-model="setting.fridayColour" model="hex"></verte>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col cols="12" sm="12" md="3" lg="3" xl="3">
                        <b-form-group label="Preview" label-for="gradeLeves">
                            <template>
                                <b-badge :style="{ 'background-color': setting.fridayColour, color: setting.fridayText }" class="text-capitalize">
                                    FRIDAY
                                </b-badge>
                            </template>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row class="mt-2">
                    <b-col cols="12" sm="12" md="3" lg="3" xl="3">
                        <b-form-group label="Select Text Colour" label-for="gradeLeves">
                            <validation-provider #default="{ errors }" name="chartColour">
                                <verte display="widget" :showHistory="null" v-model="setting.saturdayText" model="hex"></verte>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col cols="12" sm="12" md="3" lg="3" xl="3">
                        <b-form-group label="Select Background Colour" label-for="gradeLeves">
                            <validation-provider #default="{ errors }" name="chartColour">
                                <verte display="widget" :showHistory="null" v-model="setting.saturdayColour" model="hex"></verte>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col cols="12" sm="12" md="3" lg="3" xl="3">
                        <b-form-group label="Preview" label-for="gradeLeves">
                            <template>
                                <b-badge :style="{ 'background-color': setting.saturdayColour, color: setting.saturdayText }" class="text-capitalize">
                                    SATURDAY
                                </b-badge>
                            </template>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row class="mt-2">
                    <b-col cols="12" sm="12" md="3" lg="3" xl="3">
                        <b-form-group label="Select Text Colour" label-for="gradeLeves">
                            <validation-provider #default="{ errors }" name="chartColour">
                                <verte display="widget" :showHistory="null" v-model="setting.sundayText" model="hex"></verte>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col cols="12" sm="12" md="3" lg="3" xl="3">
                        <b-form-group label="Select Background Colour" label-for="gradeLeves">
                            <validation-provider #default="{ errors }" name="chartColour">
                                <verte display="widget" :showHistory="null" v-model="setting.sundayColour" model="hex"></verte>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col cols="12" sm="12" md="3" lg="3" xl="3">
                        <b-form-group label="Preview" label-for="gradeLeves">
                            <template>
                                <b-badge :style="{ 'background-color': setting.sundayColour, color: setting.sundayText }" class="text-capitalize">
                                    SUNDAY
                                </b-badge>
                            </template>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="12" class="mr-1 float-right">
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-2 mr-1 float-right" @click="validationForm">
                            Save changes
                        </b-button>
                    </b-col>
                </b-row>
            </b-card>
        </validation-observer>
    </b-overlay>
</template>

<script>
import { BProgress, BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import store from "@/store";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, alphaNum, email } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ProfileCropper from "@core/components/image-cropper/ProfileCropper.vue";
import axiosIns from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import settingsStoreModule from "../settingsStoreModule";
import { onUnmounted } from "@vue/composition-api";
import Verte from "verte";
import "verte/dist/verte.css";

export default {
    components: {
        BButton,
        BForm,
        BImg,
        BFormFile,
        BFormGroup,
        BFormInput,
        BRow,
        BCol,
        BAlert,
        BCard,
        BCardText,
        BMedia,
        BMediaAside,
        BMediaBody,
        BLink,
        ValidationProvider,
        ValidationObserver,
        BProgress,
        Verte,
    },
    directives: {
        Ripple,
    },

    setup() {
        const SETTINGS_APP_STORE_MODULE_NAME = "settings";
        // Register module
        if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
        });

        const refInputEl = ref(null);
        const previewEl = ref(null);
        const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl);
        return {
            refInputEl,
            previewEl,
            inputImageRenderer,
        };
    },

    data() {
        const { refFormObserver, getValidationState } = formValidation();
        return {
            tempImage: null,
            settingURL: store.state.app.settingURL,
            selectedImage: null,
            croppedImage: null,
            percentCompleted: 0,
            formShow: false,

            setting: {
                mondayText: null,
                tuesdayText: null,
                wednesdayText: null,
                thursdayText: null,
                fridayText: null,
                saturdayText: null,
                sundayText: null,
                mondayColour: null,
                tuesdayColour: null,
                wednesdayColour: null,
                thursdayColour: null,
                fridayColour: null,
                saturdayColour: null,
                sundayColour: null,
            },

            mainProps: {
                blank: false,
                blankColor: "#777",
                width: 200,
                height: 30,
                class: "m1",
            },
            required,
        };
    },
    methods: {
        updateSetting() {
            this.formShow = true;

            store
                .dispatch("settings/updateSetting", this.setting)
                .then((response) => {
                    if (response.status == 200) {
                        this.$swal({
                            title: "Success!",
                            text: "Website settings has been updated!",
                            icon: "success",
                            customClass: {
                                confirmButton: "btn btn-success",
                            },
                            buttonsStyling: false,
                        });
                    }
                    this.formShow = false;
                })
                .catch((error) => {
                    this.formShow = false;
                    this.$swal({
                        title: "Error!",
                        text: "User Could not be updated",
                        icon: "error",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });

                    console.log(error);
                });
        },
        validationForm() {
            return new Promise((resolve, reject) => {
                this.$refs.userRules.validate().then((success) => {
                    if (success) {
                        this.updateSetting();
                    } else {
                        this.$swal({
                            title: "Error",
                            text: "Required fields can't be left blank.",
                            icon: "error",
                            showCancelButton: false,
                            confirmButtonText: "OK",
                        });
                        //    reject();
                    }
                });
            });
        },
    },
    created() {
        store
            .dispatch("settings/fetchSettings")
            .then((response) => {
                this.setting = response.data;
                this.formShow = false;
            })
            .catch((error) => {
                this.formShow = false;
                this.$swal({
                    title: "Error!",
                    text: "Password could not be changed",
                    icon: "error",
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: false,
                });
                console.log(error);
            });
    },
};
</script>

<style scoped>
.uploadButton {
    position: absolute;
    top: -8px;
    right: 85px;
    margin: 2px;
    padding: 3px;
    border-radius: 6px;
    color: #fff;
    background-color: #ff0000;
    cursor: pointer;
    opacity: 0.5;
}

.uploadButton:hover {
    opacity: 1;
}

.btn-file {
    position: relative;
    overflow: hidden;
}

.btn-file input[type="file"] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
}
</style>
